const tables = {
	initLoad() {

    if ($('.table').length == 0) return

		this.tablesInit();
	},

	tablesInit() {
    	$('.table .btn-wrapper').on('click', function() {

				$( '.table' ).removeClass('active');
				$( this ).parent().toggleClass('active');

      });
  	},

}

module.exports = tables;
