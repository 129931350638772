const utils = {
  initLoad(){
    utils.resizeWindow()
  },
  resizeWindow() {
    if ($(window).outerWidth() > 767) return;

    const elsH = [".headerMenu__mobile",".home .immagine-full:first-child"];
    elsH.forEach((el) => {
      if ($(el).length > 0) $(el).css("height", $(window).outerHeight());
    });
  },
}

export default utils
