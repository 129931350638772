const configs = [
  {
    class: 'fadeIn',
    set: { opacity: 0 },
    start: 'top 75%',
    end: '+50%',
    markers: false,
    onEnter: { duration:1, opacity: 1, stagger: { each: 0.1, ease: "Power2.easeOut" }, ease: "Power2.easeOut" }
  },
  {
    class: 'fadeInUpDelay',
    set: { y: 20, opacity: 0 },
    start: 'top 75%',
    end: '+50%',
    markers: true,
    onEnter: { delay: 0.25, duration:.75, opacity: 1, y: 0, stagger: { each: 0.1, ease: "Power2.easeOut" }, ease: "Power2.easeOut" }
  },
  {
    class: 'fadeInUp',
    set: { y: 20, opacity: 0 },
    start: 'top 75%',
    end: '+50%',
    markers: true,
    onEnter: { duration:.75, opacity: 1, y: 0, stagger: { each: 0.1, ease: "Power2.easeOut" }, ease: "Power2.easeOut" }
  },
  {
    class: 'fadeInLeft',
    set: { xPercent: -100},
    start: 'top 75%',
    markers: false,
    onEnter: { duration:2, xPercent: 0, stagger: { each: 0.25 },  }
  },
  {
    class: 'maskFromLeft',
    set: { clipPath: 'polygon(0 0, 0 0, 0 100%, 0% 100%)'},
    start: 'top 75%',
    markers: false,
    onEnter: { clipPath: 'polygon(0 0, 100% 0%, 100% 100%, 0% 100%)', stagger: { each: 0.15 },  }
  },
  {
    class: 'zoomOutYatch',
    set: { scale: 1.13},
    start: 'top bottom',
    markers: false,
    onEnter: { duration:1.15, scale: 1, stagger: { each: 0.15, ease: "Power2.easeOut" }, ease: "Power2.easeOut" }
  },
  {
    class: 'zoomOut',
    set: { scale: 1.13},
    start: 'top center',
    markers: false,
    onEnter: { duration:1.15, scale: 1, stagger: { each: 0.15, ease: "Power2.easeOut" }, ease: "Power2.easeOut" }
  },
  {
    class: 'zoomOutDelay',
    set: { scale: 1.13},
    start: 'top 60%',
    markers: false,
    onEnter: { delay:0.2, duration:1.15, scale: 1, stagger: { each: 0.15, ease: "Power2.easeOut" }, ease: "Power2.easeOut" }
  },
  {
    class: 'rotate',
    set: { rotate: 0},
    repeat: -1,
    repeatDelay: 1,
    start: 'top 75%',
    markers: false,
    onEnter: { duration:1,rotate: 360, stagger: { each: 0.15 },  }
  }
]

export default configs
