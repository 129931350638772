const equipaggiamento = {


  initLoad() {

    equipaggiamento.configView();
    equipaggiamento.showAll();
  },
  configView() {

    if ($('.table').length == 0) return

    // remove last two children from each side
    $('.equipaggiamento_barca__wrapper').find('.left').find('.blocco:gt(-3)').hide();
    $('.equipaggiamento_barca__wrapper').find('.right').find('.blocco:gt(-3)').hide();

  },
  showAll() {

    if ($('.link-wrapper.no-smoothState').length == 0) return

    $('.equipaggiamento_barca__wrapper').find('.link-wrapper.no-smoothState').on('click', function (event) {

      event.preventDefault();

      $('.equipaggiamento_barca__wrapper').find('.left').find('.blocco:gt(-3)').show();
      $('.equipaggiamento_barca__wrapper').find('.right').find('.blocco:gt(-3)').show();

      $('.equipaggiamento_barca__wrapper').find('.link-wrapper.no-smoothState').remove();
    })
  }

}

module.exports = equipaggiamento;
