import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import configs from "./animConfigs";

gsap.registerPlugin(ScrollTrigger);

const animations = {
  initLoad() {
    // this.addDelay();
    this.configAnims();
    ScrollTrigger.refresh();
  },

  // as we use flexbile content, we must add dynamcally in js
  // a class to the first section of page in order to
  // get it later and add a delay
  // addDelay() {
  //   let firstSection = $("#main_cont section:first-child");

  //   configs.forEach((config) => {
  //     let configClass = `.${config.class}`;
  //     if (firstSection.find(configClass)) {
  //       firstSection.find(configClass).attr("data-delay",0.5);
  //     }
  //   });
  // },
  configAnims() {
    configs.forEach((config) => {
      let configClass = `.${config.class}`;
      let configSet = config.set;
      let configStart = config.start;
      let configMarkers = config.markers;
      let configOnEnter = config.onEnter;

      if ($(configClass).length > 0) {
        gsap.set($(configClass), configSet);

        ScrollTrigger.batch(configClass, {
          // markers: configMarkers,
          // interval: 0.1,
          // markers: true,
          start: configStart,
          onEnter: function (batch) {
            console.log(batch);
            gsap.to(batch, configOnEnter);
          },
        });
      }
    });



  },
  // configAnims() {
  //   configs.forEach((config) => {
  //     let configClass = `.${config.class}`;
  //     let configSet = config.set;
  //     let configStart = config.start;
  //     let configMarkers = config.markers;
  //     let configOnEnter = config.onEnter;

  //     if ($(configClass).length > 0) {
  //       $(configClass).each(function () {
  //         let item = $(this);
  //         gsap
  //           .timeline({
  //             scrollTrigger: {
  //               trigger: item,
  //               start: configStart,
  //               markers: true,
  //               scrub: true,
  //             },
  //           })
  //           .fromTo(item, configSet, configOnEnter)
  //       });
  //     }
  //   });
  // },
};

module.exports = animations;
