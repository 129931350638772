import $ from 'jquery'
import '../sass/main.scss';
import menu from './menu';
import lazyLoad from './lazyLoad';
import smoothState from './smoothState';
import slider from './slider';
import tables from './tables';
import equipaggiamento from './equipaggiamento';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import animations from './animations';
import utils from './utils';

window.$ = require('jquery');
window.jQuery = require('jquery');

const siteGlobal = {
  initReady() {
    smoothState.initReady();
  },
  initLoad() {
  	lazyLoad.initLoad();
    menu.initLoad();
    slider.initLoad();
    tables.initLoad();
    equipaggiamento.initLoad();
    utils.initLoad();
    animations.initLoad();
    // ScrollTrigger.refresh()
  },
  rebuildAllEvents() {
    lazyLoad.initLoad();
    menu.initLoad();
    slider.initLoad();
    tables.initLoad();
    equipaggiamento.initLoad();
    utils.initLoad();
    animations.initLoad();
    // ScrollTrigger.refresh()
  },
  initScroll( ){
    menu.menuFixed()
  },
  initResize() {
  },
  rebuild() {
    siteGlobal.rebuildAllEvents();
  },
};

$(document).ready(() => {
  siteGlobal.initReady();
});

$(document).on('rebuild', () => {
  siteGlobal.rebuild();
});

$(window).on('load', () => {
  $('#preloader').addClass('loaded');
  siteGlobal.initLoad();
});

$(window).on('resize', () => {
  siteGlobal.initResize();
});

$(window).on('scroll', () => {
  siteGlobal.initScroll();
});
