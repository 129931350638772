import smoothState from 'smoothState';
import { ScrollTrigger } from "gsap/ScrollTrigger";

const pageTransition = {
	initReady() {
		this.initPageTransition();
	},

	initPageTransition() {
		const options = {
			anchors: 'a',
			forms: 'form',
			allowFormCaching: false,
			scroll: false,
		    blacklist: '.no-smoothState , .no-smoothState > a, .wpcf7-form',
		    prefetch: false,
	    	cacheLength: 0,
		    onBefore: function($currentTarget, $container) {
		    },
		    onStart: {
		    	duration: 400,
		    	render: function ($container) {

						setTimeout(() => {
							document.body.scrollTop = document.documentElement.scrollTop = 0;
						}, 400);
		    		if ($('.header__hamburger').hasClass('opened')) {
		    			$('.header__hamburger, .header, .header__menu').removeClass('opened');
				        setTimeout(function(){
			        		$('#main').addClass('is-exiting');
				        }, 200)
		    		} else {
			        	$('#main').addClass('is-exiting');
		    		}
		        }
		    },
		    onReady: {
		    	render: function ($container, $newContent) {
			        $container.html($newContent);
			    }
		    },
		    onAfter: function($container, $newContent){

					// update body class
					$('body').attr('class', $('#bodydata').attr('class'))

		        $(document).trigger('rebuild');

						var cf_selector = 'div.wpcf7 > form';
						var cf_forms = $newContent.find(cf_selector);
						if (cf_forms.length>0) {
							$(cf_selector).each(function() {
								var $form = $(this);
								wpcf7.init( $form[0] );
							});
						}

		        setTimeout(function(){
		       		$('#main').removeClass('is-exiting');
							 ScrollTrigger.refresh()
		        }, 350);
		    }
		};

		$('#main').smoothState(options).data('smoothState');
  	},

}

export default pageTransition;
