import 'slick-carousel'

const slider = {

  initLoad() {
    slider.setSlider()
  },
  setSlider() {

    if ($('.slider__wrapper').length == 0) return

    //  storia
    $('#storia-slider-imbarcazioni .slider__wrapper').slick({
      slidesToShow: 3,
      variableWidth: true,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 2500, // delay
      speed: 700,
      infinite: false,
      dots: false,
      arrows: true,
      swipe: true,
      prevArrow: '<span class="arrow arrow--color-blue arrow--pos-left"></span>',
      nextArrow: '<span class="arrow arrow--color-blue arrow--pos-right"></span>',
      responsive: [{
        breakpoint: 767,
        settings: {
          slidesToShow: 1
        }

      }]
    });

    $('#storia-slider-persone .slider__wrapper').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 3500, // delay
      infinite: false,
      speed: 700,
      dots: false,
      arrows: true,
      swipe: true,
      prevArrow: '<span class="arrow arrow--color-blue arrow--pos-left"></span>',
      nextArrow: '<span class="arrow arrow--color-blue arrow--pos-right"></span>',
    });

  }
}

export default slider;
