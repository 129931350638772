const menu = {
	initLoad() {
		this.menuInit();
		this.menuColors();
		this.langSelect();
		this.submenuClick();
	},

	menuInit() {
		$('.headerMenu__hamburger').on('click', () => {
			$('.headerMenu__hamburger, .header, .headerMenu__mobile').toggleClass('opened');

		})
	},
	menuColors() {

		const els = ['#contatti','#policy']
		els.forEach(el => {

			if ($(el).length > 0) {

				$('.header').addClass('header--color-blue');
			}

		});
	},
	menuFixed() {
		const scroll = $(window).scrollTop();

		scroll > 150 ? $('.header').addClass('header--color-blue header--background-white') : $('.header').removeClass('header--color-blue header--background-white'), this.menuColors();

	},
	langSelect() {
		$('.lang-select').on('click', function () {

			let langsElement = $(this)
			let langsList = langsElement.find('ul')

			// is visible?
			if (langsList.is(':visible')) langsElement.removeClass('active'), langsList.slideUp(350)
			else langsElement.addClass('active'), langsList.slideDown(350)

		})

	},
	submenuClick() {

		$('.menu-item-has-children').on('click', function () {

			$(this).toggleClass('opened')
			$(this).find('.sub-menu').toggleClass('active')

		})
	}

}

module.exports = menu;
